import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Videos = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title='Videos' />
      <h2 style={{ display: 'none', textAlign: 'center' }}>Videos</h2>
      <div className='mobile-wrap' style={{ display: 'flex' }}>
        <figure className='videos__video-container'>
          <iframe
            className='Videos__iframe'
            title='The 100 Year Prayer'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/6n0_rh5PnAY'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </figure>
        <figure className='videos__video-container'>
          <iframe
            className='Videos__iframe'
            title='Gamble with God'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/_kMNPXll9L0'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </figure>
        <figure className='videos__video-container'>
          <iframe
            className='Videos__iframe'
            title='When I Was Lost (Virtual Choir #8)'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/Eh5hymw-OaE'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
          <figcaption>I'm in the top row, fifth from the right</figcaption>
        </figure>
        <figure className='videos__video-container'>
          <iframe
            className='Videos__iframe'
            title='Angels We Have Heard On High (Virtual Choir #9)'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/ywVUSj39hoc'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </figure>

        <div
          style={{
            padding: '16px',
            border: 'solid 1px #e0e0e0',
            borderRadius: '8px',
          }}
        >
          <figure style={{ margin: 0 }}>
            <figcaption>
              I was interviewed some time back for the radio program{' '}
              <em>Making Your Life Count Radio.</em>
            </figcaption>
            <audio
              style={{ width: '100%', marginTop: '10px' }}
              controls
              src='audio/radio_interview_mylc2_12-18-2023_carol_nordman.mp3'
            />
          </figure>
        </div>
      </div>
    </Layout>
  );
};

export default Videos;
